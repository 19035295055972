export default class Filter {
  init() {
    const dropdownOptions = document.getElementById('dropdown-options');
    document.querySelectorAll('.mobile-btn').forEach(btn => {
      btn.addEventListener('click', () => {
        dropdownOptions.classList.toggle('active');
      });
    });
    const search = document.querySelector('input[name="search"]');
    const date = document.querySelector('input[name="date-picker"]');
    const dropdown = document.querySelector('.Dropdown');
    const checkbox = document.querySelector('input[type=checkbox]');
    console.log(checkbox);
    if (search) {
      search.addEventListener('keypress', event => {
        if (event.key === 'Enter') {
          dropdownOptions.submit();
        }
      });
    }
    if (date) {
      date.addEventListener('changeDate', () => {
        dropdownOptions.submit();
      });
    }
    if (dropdown) {
      dropdown.addEventListener('removeItem', () => {
        setTimeout(() => {
          dropdownOptions.submit();
        }, 50);
      });
    }
    if (checkbox) {
      checkbox.addEventListener('change', () => {
        dropdownOptions.submit();
      });
    }
  }
}