import Datepicker from '../../../node_modules/vanillajs-datepicker/js/Datepicker';

export default class Datepick {
  init() {
    const elems = document.querySelectorAll('input[name="date-picker"]');
    elems.forEach(elem => {
      const datepicker = new Datepicker(elem, {
        autohide: true,
        prevArrow: '',
        nextArrow: '',
        format: 'dd/mm/yyyy',
        weekStart: 1
      });
    });
  }
}
