export default class Banner {
  init() {
    const firstBanner = document.querySelector(".block-banner");
    if (firstBanner) {
      firstBanner.style.paddingTop = "0";
      firstBanner.firstElementChild.style.borderRadius = "32px 32px 0 0";
      console.log(firstBanner.firstChild);
      const banners = document.querySelectorAll(".block-banner");
      banners.forEach((banner, index, array) => {
        if (index === array.length - 1) {
          banner.style.paddingBottom = "64px";
          banner.firstElementChild.style.borderRadius = "0 0 32px 32px";
        }
      });
    }
  }
}
