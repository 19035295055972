import inView from 'in-view';

export default class Fact {
  init() {
    const elements = document.querySelectorAll('.Fact--animate');

    elements.forEach(fact => {
      const number = fact.querySelector('#jsNumber');
      const circle = fact.querySelector('#jsCircle');
      const targetNumber = parseInt(fact.dataset.number, 10);
      const customDuration = parseInt(fact.dataset.duration, 10);
      const duration = customDuration ? customDuration : 1200;
      const step = duration / 60;
      fact.percentage = 0;

      const paint = () => {
        number.innerHTML = parseInt(targetNumber*fact.percentage/100, 10);
        circle.style.strokeDashoffset = `${Math.floor(301.44*((100 - fact.percentage) / 100))}px`;
      }

      fact.animate = () => {
        const interval = setInterval(() => {
          fact.percentage = Math.min(100, fact.percentage + 1);
          if (fact.percentage >= 100) {
            clearInterval(interval);
            fact.percentage = 100;
          }
          paint();
        }, step);
      };
    });

    inView('.Fact--animate').on('enter', element => {
      if (element.percentage == 0) element.animate();
    });
  }
}
