export default class Menu {
  init() {
    const button = document.getElementById('menu-btn');
    const menuItems = document.querySelector('.mobile-menu-items');
    let isActive = false;
    //const espaBanner = document.querySelector(".espa-banner");

    //const isMonumentsPage = document.body.classList.contains("post-type-archive-monuments_museums");

    //if (isMonumentsPage && espaBanner) {
    //  espaBanner.classList.add("hide-banner");
    //}

    window.addEventListener("DOMContentLoaded", (event) => {
      const menu = document.querySelector(".menu-navigation");
      const sentinel = document.querySelector(".header-logos");

      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (!entry.isIntersecting) {
            menu.style.position = "fixed";
            menu.style.top = "0";
          } else {
            menu.style.position = "relative";
          }
        });
      });

      observer.observe(sentinel);
    });

    button.addEventListener('click', () => {
      if (isActive) {
        button.classList.remove('active');
        menuItems.style.display = 'none';
        isActive = false;
      } else {
        button.classList.add('active');
        menuItems.style.display = 'flex';
        isActive = true;
      }
    });

    const tempPath = window.location.pathname;
    const currentPath = tempPath.slice(0, -1);
    const menuItemsUnderline = document.querySelectorAll('.menu-item-underline');
    menuItemsUnderline.forEach(item => {
      if (item.getAttribute('href') === currentPath) {
        item.classList.add('active');
      } else {
        item.classList.remove('active');
      }
    });
    //header-logos
    let options = {
      
        rootMargin: "0px",
        threshold: 1.0,
      };

      let observer = new IntersectionObserver(callback, options);
      let target = document.querySelector(".header-logos");
      let menu = document.querySelector(".menu-navigation");
      observer.observe(target);

      function callback(entries, observer) {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            menu.classList.add("fixed");
          } else {
            menu.classList.remove("fixed");
          }
        });
      }
  }
}
