export default class ActiveMenuItem {
    init() {
            const tempPath = window.location.pathname;
            const currentPath = tempPath.slice(0, -1);
            const menuItems = document.querySelectorAll('.PT-button');
            menuItems.forEach((item) => {
              if (item.getAttribute('href') === currentPath) {
                item.classList.add('active');
              } else {
                item.classList.remove('active');
              }
            });
    }
}