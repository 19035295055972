// export default class RangePicker {
//   init() {
//     let rangeMin = 0;
//     const range = document.querySelector(".range-selected");
//     const rangeInput = document.querySelectorAll(".range-input input");
//     const rangePrice = document.querySelectorAll(".range-price input");
//     rangeInput.forEach((input) => {
//       input.addEventListener("input", (e) => {
//         let minRange = parseInt(rangeInput[0].value);
//         let maxRange = parseInt(rangeInput[1].value);
//         if (maxRange - minRange < rangeMin) {
//           if (e.target.className === "min") {
//             rangeInput[0].value = maxRange - rangeMin;
//           } else {
//             rangeInput[1].value = minRange + rangeMin;
//           }
//         } else {
//           rangePrice[0].value = minRange;
//           rangePrice[1].value = maxRange;
//           range.style.left = (minRange / rangeInput[0].max) * 100 + "%";
//           if (minRange < rangeInput[0].max/3) {
//             range.style.left = (minRange / rangeInput[0].max) * 100 + 1 + "%";
//           }
//           if (minRange > rangeInput[1].max/1.5) {
//             range.style.left = (minRange / rangeInput[0].max) * 100 - 1 + "%";
//           }
//           range.style.right = 100 - (maxRange / rangeInput[1].max) * 100  + "%";
//           if (maxRange > rangeInput[0].max/3 && maxRange > rangeInput[1].max/1.5 ) {
//             range.style.right = 100 - (maxRange / rangeInput[1].max) * 100 + 1 + "%";
//           }
//           if (maxRange < rangeInput[1].max/1.5 && maxRange < rangeInput[0].max/3) {
//             range.style.right = 100 - (maxRange / rangeInput[1].max) * 100 - 1 + "%";
//           }
//         }
//       });
//     });
//     rangePrice.forEach((input) => {
//       input.addEventListener("input", (e) => {
//         let minPrice = rangePrice[0].value;
//         let maxPrice = rangePrice[1].value;

//         if (maxPrice - minPrice < rangeMin) {
//             rangePrice[0].value = maxPrice - rangeMin;

//         } else {
//           rangeInput[0].value = minPrice;
//           rangeInput[1].value = maxPrice;
//           range.style.left = (minPrice / rangePrice[0].max) * 100 + "%";
//           if (minPrice < rangePrice[0].max/3) {
//             range.style.left = (minPrice / rangePrice[0].max) * 100 + 1 + "%";
//           }
//           if (minPrice > rangePrice[1].max/1.5) {
//             range.style.left = (minPrice / rangePrice[0].max) * 100 - 1 + "%";
//           }
//           range.style.right = 100 - (maxPrice / rangePrice[1].max) * 100  + "%";
//           if (maxPrice > rangePrice[0].max/3 && maxPrice > rangePrice[1].max/1.5 ) {
//             range.style.right = 100 - (maxPrice / rangePrice[1].max) * 100 + 1 + "%";
//           }
//           if (maxPrice < rangePrice[1].max/1.5 && maxPrice < rangePrice[0].max/3) {
//             range.style.right = 100 - (maxPrice / rangePrice[1].max) * 100 - 1 + "%";
//           }
//         }
//       });
//     });
//   }
// }

import * as noUiSlider from "nouislider";

export default class RangePicker {
  init() {
    var slider = document.getElementById("slider");
    var inputmin = document.getElementById("min");
    var inputmax = document.getElementById("max");

    if (slider == null) {
      return;
    }

    noUiSlider.create(slider, {
      start: [1450, 2023],
      connect: true,
      range: {
        min: 1450,
        max: 2023,
      },
      format: {
        to: (v) => parseFloat(v).toFixed(0),
        from: (v) => parseFloat(v).toFixed(0)
    }
    });
    
    slider.noUiSlider.on("update", function (values, handle) {
      var value = values[handle];
      if (handle) {
        inputmax.value = value;
      } else {
        inputmin.value = value;
      }
    });
    inputmin.addEventListener("change", function () {
      slider.noUiSlider.set([this.value, null]);
    });
    inputmax.addEventListener("change", function () {
      slider.noUiSlider.set([null, this.value]);
    });
  }
}
